<template>
    <div>
        <vx-card
            title="Plans Overview"
            :subtitle="subTitle"
            class="no-over mb-base"
        >
            <div class="vx-row">
                <div class="vx-col w-full">
                    <vs-table v-if="sortedPlans" stripe noDataText="">
                        <template slot="header">
                            <h3>Summary</h3>
                        </template>
                        <template slot="thead">
                            <vs-th>Plan</vs-th>
                            <vs-th>Total brokers</vs-th>
                            <vs-th>Active brokers</vs-th>
                            <vs-th>Total revenue from active</vs-th>
                        </template>

                        <template>
                            <vs-tr
                                v-for="(plan, index) in sortedPlans"
                                :key="index"
                            >
                                <vs-td>{{ plan.name }}</vs-td>

                                <vs-td>
                                    {{ plan.brokers.length }}
                                </vs-td>
                                <vs-td>
                                    {{ planTotal(plan)[0] }}
                                </vs-td>

                                <vs-td>£{{ planTotal(plan)[1] }}</vs-td>
                            </vs-tr>
                            <vs-tr>
                                <vs-td></vs-td>
                                <vs-td
                                    ><span class="font-bold">{{
                                        brokers.length
                                    }}</span></vs-td
                                >
                                <vs-td
                                    ><span class="font-bold">{{
                                        activeBrokers.length
                                    }}</span></vs-td
                                >
                                <vs-td
                                    ><span class="font-bold"
                                        >£{{ totalAmount }}</span
                                    ></vs-td
                                >
                            </vs-tr>
                        </template>
                    </vs-table>
                </div>
            </div>
        </vx-card>

        <vx-card
            v-for="(plan, index) in sortedPlans"
            :key="index"
            class="mb-base"
        >
            <vs-table v-if="plan.brokers" stripe :data="plan.brokers" sort>
                <template slot="header">
                    <h3>{{ plan.name }}</h3>
                </template>
                <template slot="thead">
                    <vs-th sortKey="company_name">Broker</vs-th>
                    <vs-th sortKey="active">Active</vs-th>
                    <vs-th sortKey="start_date">Start Date</vs-th>
                    <vs-th sortKey="direct_debit_status">Direct Debit</vs-th>
                    <vs-th sortKey="go_card_less_customer_id"
                        >Go Cardless</vs-th
                    >
                </template>

                <template slot-scope="{ data }">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td :data="data[indextr]">
                            <span
                                class="link"
                                v-on:click="
                                    $router.push({
                                        name: 'broker',
                                        params: { pk: data[indextr].email },
                                    })
                                "
                            >
                                {{ data[indextr].company_name }}</span
                            >
                        </vs-td>

                        <vs-td :data="data[indextr]">
                            <feather-icon
                                :icon="
                                    data[indextr].active ? 'CheckIcon' : 'XIcon'
                                "
                                svg-classes="text-primary"
                                :svgClasses="
                                    data[indextr].active
                                        ? 'text-success'
                                        : 'text-danger'
                                "
                            />
                        </vs-td>

                        <vs-td :data="data[indextr]">{{
                            formatDate(data[indextr].start_date)
                        }}</vs-td>

                        <vs-td :data="data[indextr]">
                            <feather-icon
                                :icon="
                                    data[indextr].direct_debit_status
                                        ? 'CheckIcon'
                                        : 'XIcon'
                                "
                                svg-classes="text-primary"
                                :svgClasses="
                                    data[indextr].direct_debit_status
                                        ? 'text-success'
                                        : 'text-danger'
                                "
                            />
                        </vs-td>
                        <vs-td :data="data[indextr]">
                            {{ data[indextr].go_card_less_customer_id }}<br />
                            {{ data[indextr].go_card_less_mandate_id }}
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>
        </vx-card>
    </div>
</template>

<script>
import { http } from '@/services'
import { textFormat } from '@/mixins/TextFormat'

export default {
    name: 'BrokerReportPlansCard',
    mixins: [textFormat],
    components: {},
    mounted() {
        this.brokerReportPlansFetch()
    },
    watch: {},
    computed: {
        subTitle() {
            return 'Broker Plans'
        },
        activeBrokers() {
            if (this.brokers) {
                var active_accounts = this.brokers.filter(
                    (broker) =>
                        broker.active &&
                        (broker.start_date === null ||
                            new Date(broker.start_date) < new Date())
                )
                return active_accounts
            }
            return []
        },
        totalAmount() {
            var total = 0
            if (this.brokers) {
                var active_accounts = this.activeBrokers

                for (var i = 0; i < active_accounts.length; i++) {
                    if (this.brokers[i].plan)
                        total += Number(this.brokers[i].plan.monthly_charge)
                }
            }

            return total
        },
    },
    data() {
        return {
            brokers: null,
            sortedPlans: null,
        }
    },
    methods: {
        brokerReportPlansFetch() {
            http.get('report_broker_plans')
                .then((response) => {
                    this.brokers = response.data
                    this.sortBrokers()
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        sortBrokers() {
            var data = {}
            if (this.brokers.length) {
                for (var i = 0; i < this.brokers.length; i++) {
                    if (
                        this.brokers[i].plan &&
                        !(this.brokers[i].plan.plan in data)
                    ) {
                        data[this.brokers[i].plan.plan] = {
                            name: this.brokers[i].plan.plan,
                            amount: this.brokers[i].plan.monthly_charge,
                            brokers: this.brokers.filter(
                                (broker) =>
                                    broker.plan &&
                                    broker.plan.plan ==
                                        this.brokers[i].plan.plan
                            ),
                        }
                    } else {
                        data['No Plan'] = {
                            name: 'No plan set',
                            amount: null,
                            brokers: this.brokers.filter(
                                (broker) => broker.plan === null
                            ),
                        }
                    }
                }
            }

            this.sortedPlans = data
        },
        planTotal(plan) {
            if (plan) {
                var active_accounts = plan.brokers.filter(
                    (broker) =>
                        broker.active &&
                        (broker.start_date === null ||
                            new Date(broker.start_date) < new Date())
                )
                return [
                    active_accounts.length,
                    active_accounts.length * plan.amount,
                ]
            }
            return [0, 0]
        },
    },
}
</script>
