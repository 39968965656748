var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vx-card',{staticClass:"no-over mb-base",attrs:{"title":"Plans Overview","subtitle":_vm.subTitle}},[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full"},[(_vm.sortedPlans)?_c('vs-table',{attrs:{"stripe":"","noDataText":""}},[_c('template',{slot:"header"},[_c('h3',[_vm._v("Summary")])]),_c('template',{slot:"thead"},[_c('vs-th',[_vm._v("Plan")]),_c('vs-th',[_vm._v("Total brokers")]),_c('vs-th',[_vm._v("Active brokers")]),_c('vs-th',[_vm._v("Total revenue from active")])],1),[_vm._l((_vm.sortedPlans),function(plan,index){return _c('vs-tr',{key:index},[_c('vs-td',[_vm._v(_vm._s(plan.name))]),_c('vs-td',[_vm._v(" "+_vm._s(plan.brokers.length)+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(_vm.planTotal(plan)[0])+" ")]),_c('vs-td',[_vm._v("£"+_vm._s(_vm.planTotal(plan)[1]))])],1)}),_c('vs-tr',[_c('vs-td'),_c('vs-td',[_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.brokers.length))])]),_c('vs-td',[_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.activeBrokers.length))])]),_c('vs-td',[_c('span',{staticClass:"font-bold"},[_vm._v("£"+_vm._s(_vm.totalAmount))])])],1)]],2):_vm._e()],1)])]),_vm._l((_vm.sortedPlans),function(plan,index){return _c('vx-card',{key:index,staticClass:"mb-base"},[(plan.brokers)?_c('vs-table',{attrs:{"stripe":"","data":plan.brokers,"sort":""},scopedSlots:_vm._u([{key:"default",fn:function({ data }){return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr},[_c('vs-td',{attrs:{"data":data[indextr]}},[_c('span',{staticClass:"link",on:{"click":function($event){return _vm.$router.push({
                                    name: 'broker',
                                    params: { pk: data[indextr].email },
                                })}}},[_vm._v(" "+_vm._s(data[indextr].company_name))])]),_c('vs-td',{attrs:{"data":data[indextr]}},[_c('feather-icon',{attrs:{"icon":data[indextr].active ? 'CheckIcon' : 'XIcon',"svg-classes":"text-primary","svgClasses":data[indextr].active
                                    ? 'text-success'
                                    : 'text-danger'}})],1),_c('vs-td',{attrs:{"data":data[indextr]}},[_vm._v(_vm._s(_vm.formatDate(data[indextr].start_date)))]),_c('vs-td',{attrs:{"data":data[indextr]}},[_c('feather-icon',{attrs:{"icon":data[indextr].direct_debit_status
                                    ? 'CheckIcon'
                                    : 'XIcon',"svg-classes":"text-primary","svgClasses":data[indextr].direct_debit_status
                                    ? 'text-success'
                                    : 'text-danger'}})],1),_c('vs-td',{attrs:{"data":data[indextr]}},[_vm._v(" "+_vm._s(data[indextr].go_card_less_customer_id)),_c('br'),_vm._v(" "+_vm._s(data[indextr].go_card_less_mandate_id)+" ")])],1)})}}],null,true)},[_c('template',{slot:"header"},[_c('h3',[_vm._v(_vm._s(plan.name))])]),_c('template',{slot:"thead"},[_c('vs-th',{attrs:{"sortKey":"company_name"}},[_vm._v("Broker")]),_c('vs-th',{attrs:{"sortKey":"active"}},[_vm._v("Active")]),_c('vs-th',{attrs:{"sortKey":"start_date"}},[_vm._v("Start Date")]),_c('vs-th',{attrs:{"sortKey":"direct_debit_status"}},[_vm._v("Direct Debit")]),_c('vs-th',{attrs:{"sortKey":"go_card_less_customer_id"}},[_vm._v("Go Cardless")])],1)],2):_vm._e()],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }